import Carousel from 'react-bootstrap/Carousel';

import 'css/src/components/public-pages/common-sections/CompanyInfoSection';

import React, { useState } from 'react';

import IconCircle from 'js/src/components/common/ui/IconCircle';

type Props = {
    backgroundUrl?: string,
    logoUrl?: string,
    description?: string,
    customerName?: string,
    customerDesignation?: string,
    storyUrl?: string,
};

const CarouselItem = React.memo(function CarouselItem({
    backgroundUrl,
    logoUrl,
    description,
    customerName,
    customerDesignation,
    storyUrl,
}: Props) {
    const containerStyle = {
        background: `url(${backgroundUrl})`,
    };
    return (
        <div className="row justify-content-center">
            <div className="col-sm-10">
                <div className="use-case-container" style={containerStyle}>
                    <div className="row">
                        <div className="col-sm-12 d-flex justify-content-end">
                            <div className="d-flex flex-column content-container mx-l5-16 my-l5-16 mb-l5-8 p-l5-16 rounded">
                                <div className="my-l5-8 py-l5-16 px-l5-16">
                                    <img loading="lazy" src={logoUrl} className="carousel-logo"/>
                                </div>

                                <div className="my-l5-4 px-l5-16 case-study-text">
                                    <p>{description}</p>
                                </div>

                                <div className="my-l5-4 px-l5-16 case-study-text">
                                    <span><strong>{customerName}</strong></span>
                                    <br/>
                                    <span>
                                        {customerDesignation}
                                    </span>
                                </div>

                                <div className="my-l5-16 px-l5-16">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <hr />
                                        </div>
                                        <div className="col-sm-5">
                                            <a href={storyUrl} className="text-dark">Read story &rarr;</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const CompanyInfoSection = React.memo(function CompanyInfoSection() {
    const caseStudiesData = [
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-siemens-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-siemens.png',
            description: '“With the traditional agency, the time is usually about one week. But with Lumen5, it’s cut down from weeks to hours.”',
            customerName: 'Michelle Hsiao',
            customerDesignation: 'Visual Content Director at Siemens AG',
            storyUrl: `https://content.lumen5.com/siemens`,
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-dnv-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-dnv.png',
            description: `“Lumen5 is especially helpful for business people who don't have the technical experience to make their own videos.”`,
            customerName: 'Peter Lovegrove',
            customerDesignation: 'Media Relations and Content Manager',
            storyUrl: `https://content.lumen5.com/dnv`, // defined in contentful
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-mhi-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-mitsubishi.png',
            description: `“Thanks to Lumen5, we have been able to cut down on the time and costs related to video making.”`,
            customerName: 'Adrian Ngo',
            customerDesignation: 'Global Advertising and Sponsorships',
            storyUrl: `https://content.lumen5.com/mitsubishi`, // defined in contentful
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-ncstate-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-ncstate.png',
            description: `“We knew Lumen5 was the right tool, so there was no point in looking any further.”`,
            customerName: 'Drew Sykes',
            customerDesignation: 'Social Media Director',
            storyUrl: `https://content.lumen5.com/ncstate`, // defined in contentful
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-cms-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-cms.png',
            description: `“The best part about Lumen5 is that it's not time-consuming. It's really easy and you only need a couple of minutes to create a video because everything is included.”`,
            customerName: 'Amsal Alihodzic',
            customerDesignation: 'Senior Online Marketing Manager',
            storyUrl: `https://content.lumen5.com/cms`, // defined in contentful
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-mondadori-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-mondadori.png',
            description: '"Lumen5 helps us with what we want to achieve on social: engagement and reach."',
            customerName: 'Alfonso Biondi',
            customerDesignation: 'Social Video Specialist',
            storyUrl: `https://content.lumen5.com/mondadori`, // defined in contentful
        },
        {
            backgroundUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-berkshire-bg.png',
            logoUrl: 'https://storage.googleapis.com/lumen5-site-images/carousel-berkshire.png',
            description: '“We were very attracted to Lumen5 due to the fact it was very turnkey and very simple. We could train a team of people to assist in the production of video.”',
            customerName: 'Glen Wellbrock',
            customerDesignation: 'Senior Director of Marketing',
            storyUrl: `https://content.lumen5.com/bhhs`, // defined in contentful
        },
    ];

    const [carouselIndex, setCarouselIndex] = useState(0);

    const handleCarouselSelect = (selectedIndex: number) => {
        setCarouselIndex(selectedIndex);
    };

    const navigateToCarouselIndex = (index: number) => {
        setCarouselIndex(index);
    };


    return (
        <div className="row justify-content-center align-items-center my-l5-24 company-info-section">
            <div className="col-sm-12">
                <div className="container d-flex my-l5-16 justify-content-center flex-column">

                    <div className="carousel-l5">
                        <Carousel
                            activeIndex={carouselIndex}
                            onSelect={handleCarouselSelect}
                            interval={null}
                            indicators={false}
                            prevIcon={<IconCircle
                                materialIconName="navigate_before"
                                backgroundColor="white"
                                className="shadow"
                            />}
                            nextIcon={<IconCircle
                                materialIconName="navigate_next"
                                backgroundColor="white"
                                className="shadow"
                            />}
                        >
                            {
                                caseStudiesData.map((caseStudyData, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <CarouselItem
                                                backgroundUrl={caseStudyData.backgroundUrl}
                                                logoUrl={caseStudyData.logoUrl}
                                                description={caseStudyData.description}
                                                customerName={caseStudyData.customerName}
                                                customerDesignation={caseStudyData.customerDesignation}
                                                storyUrl={caseStudyData.storyUrl}
                                            />
                                        </Carousel.Item>
                                    );
                                })
                            }
                        </Carousel>
                    </div>

                    <div className="d-flex justify-content-center mx-l5-md-48 px-l5-md-48 my-l5-16">
                        {
                            caseStudiesData.map((caseStudyData, index) => {
                                return (
                                    <div key={index}>
                                        <img loading="lazy" className="business-logos mx-l5-16 my-l5-0 cursor-pointer" src={caseStudyData.logoUrl}
                                            onClick={() => navigateToCarouselIndex(index)}/>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </div>
        </div>

    );
});

export default CompanyInfoSection;
