import 'css/src/components/common/ui/IconCircle';

import React from 'react';
import classNames from 'classnames';


type Props = {
    materialIconName?: string,
    onClick?: VoidFunction,
    size?: 'small' | 'large',
    color?: 'color-primary' | 'text-white' | 'color-black' | 'color-grey-600',
    backgroundColor?: 'primary' | 'white' | 'accent' | 'cherry',
    className?: string,
};

const IconCircle = React.memo(function IconCircle({
    materialIconName,
    onClick,
    size,
    color,
    backgroundColor,
    className,
}: Props) {
    return (
        <div className={`icon-circle icon-overlay ${classNames(size, backgroundColor, className, {
            clickable: onClick,
        })}`} onClick={onClick}>
            <i className={`material-symbols-outlined icon-l5 ${color}`}>{materialIconName}</i>
        </div>
    );
});

export default IconCircle;
