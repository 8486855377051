import 'css/src/components/public-pages/common-sections/VideoPlayerWithButton';

import React from 'react';
import classNames from 'classnames';

import IconCircle from 'js/src/components/common/ui/IconCircle';
import Modal, { MODAL_SIZES } from 'js/src/components/common/ui/modal';
import VideoPlayer from 'js/src/components/public-pages/common-sections/VideoPlayer';


const VideoPlayerWithButton = React.memo(function VideoPlayerWithButton({
    className = '',
    imgSrc,
    videoSrc,
    onClick,
    modalPreviewOnClick = false,
}: {
    className?: string,
    imgSrc?: string,
    videoSrc: string,
    onClick?: () => void,
    modalPreviewOnClick?: boolean,
}) {
    const [modalShowing, setModalShowing] = React.useState(false);

    const onClickCallback = React.useCallback(() => {
        if (modalPreviewOnClick) {
            setModalShowing(true);
        }

        if (onClick) {
            onClick();
        }
    }, [modalPreviewOnClick, onClick]);

    const hideModal = React.useCallback(() => {
        setModalShowing(false);
    }, []);

    let modalNode = null;
    if (modalPreviewOnClick) {
        modalNode = (
            <Modal
                showing={modalShowing}
                onHidden={hideModal}
                closeable={false}
                size={MODAL_SIZES.XL}
                dialogClassName="transparent-dialog text-center"
            >
                <div className="container-fluid">
                    <VideoPlayer
                        posterUrl={imgSrc}
                        videoUrl={videoSrc}
                        showControls={true}
                        loop={false}
                        muted={false}
                        autoPlay={true}
                        classNames="m-l5-4 rounded-video focus-outline-none"
                    />
                </div>
            </Modal>
        );
    }
    return (
        <div className={classNames('video-player-with-button play-btn-container', className)}>
            <VideoPlayer
                classNames="mt-l5-8 me-l5-8 rounded-video focus-outline-none"
                posterUrl={imgSrc}
                videoUrl={videoSrc}
                clickHandler={onClickCallback}
                showControls={false}
                muted
                onHoverPlay
                loop
            />
            <IconCircle
                materialIconName="play_arrow"
                onClick={onClickCallback}
                color="color-black"
                backgroundColor="white"
                className="play-btn material-symbols-f1"
            />
            {modalNode}
        </div>
    );
});


export default VideoPlayerWithButton;
