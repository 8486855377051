import { MODAL_SIZES } from 'js/src/components/common/ui/modal';

export const customVideos = [
    {
        poster: 'https://storage.googleapis.com/lumen5-site-images/siemens_tn.png',
        aspectRatio: 'landscape',
        modalSize: MODAL_SIZES.XL,
        src: 'https://storage.googleapis.com/lumen5-site-images/siemens_prev.mp4',
    },
    {
        poster: 'https://storage.googleapis.com/lumen5-site-images/salesforce_tn.png',
        aspectRatio: 'landscape',
        modalSize: MODAL_SIZES.XL,
        src: 'https://storage.googleapis.com/lumen5-site-images/salesforce_prev.mp4',
    },
    {
        poster: 'https://storage.googleapis.com/lumen5-site-images/viasat_tn.png',
        aspectRatio: 'landscape',
        modalSize: MODAL_SIZES.XL,
        src: 'https://storage.googleapis.com/lumen5-site-images/viasat_prev.mp4',
    },
    {
        poster: 'https://storage.googleapis.com/lumen5-site-images/storaenso_tn.png',
        aspectRatio: 'landscape',
        modalSize: MODAL_SIZES.XL,
        src: 'https://storage.googleapis.com/lumen5-site-images/storaenso_prev.mp4',
    },
];

const tags = [
    {
        id: 'content-marketing',
        displayName: 'Content Marketing',
    },
    {
        id: 'communications',
        displayName: 'Communications',
    },
    {
        id: 'promotional',
        displayName: 'Promotional',
    },
    {
        id: 'people-and-culture',
        displayName: 'People & Culture',
    },
    {
        id: 'localization',
        displayName: 'Localization',
    },
];

export type HomepageVideoDetails = {
    poster: string;
    aspectRatio: string;
    modalSize: MODAL_SIZES;
    src: string;
};

const taggedVideos: Record<string, HomepageVideoDetails[]> = {
    'communications': [
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Coral%20Reef%20Protection%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Coral%20Reef%20Protection.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Zespri%20Selects%20SAP%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Zespri%20Selects%20SAP.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Textile%20Recycling%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Textile%20Recycling.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Navigating%20the%20Seas%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Navigating%20the%20Seas.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Outstanding%20Researchers.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Outstanding%20Researchers.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Fighting%20COVID-19%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Communications/Fighting%20COVID-19.mp4',
        },
    ],
    'content-marketing': [
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Hydrogen%20Economy%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Hydrogen%20Economy.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/siemens-content-TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/siemens-content.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Responsible%20Investment%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Responsible%20Investment.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Workplace%20Evolution%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Workplace%20Evolution.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/The%20Pandemic%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/The%20Pandemic.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Supply%20Chain%20Resilience%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Content%20Marketing/Supply%20Chain%20Resilience.mp4',
        },
    ],
    'localization': [
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Colliers%201%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Colliers%20(1).mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/siemens-local-TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/siemens-local.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Agility%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Agility.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Siemens%20Energy%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Siemens%20Energy.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/DNV%20GL%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/DNV%20GL.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Colliers%202%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Localization/Colliers%20(2).mp4',
        },
    ],
    'people-and-culture': [
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Women%20in%20Tech%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Women%20in%20Tech.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Volunteers%20Week%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Volunteers%20Week.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Create%20Your%20Future%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Create%20Your%20Future.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/siemens-culture-TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/siemens-culture.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Voice%20of%20Sulzer%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Voice%20of%20Sulzer.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Fundraising%20Challenge%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/People%20_%20Culture/Fundraising%20Challenge.mp4',
        },
    ],
    'promotional': [
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Global%20Leader%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Global%20Leader%20(1).mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Remote%20Auditing%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Remote%20Auditing.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Turbocompressor%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Turbocompressor.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Innovation%20Awards%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Innovation%20Awards.mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Dormakaba%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/Dormakaba%20(1).mp4',
        },
        {
            poster: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/V%20Plate%20TN.png',
            aspectRatio: 'landscape',
            modalSize: MODAL_SIZES.XL,
            src: 'https://storage.googleapis.com/lumen5-site-images/homepage/Promotional/V%20Plate.mp4',
        },
    ],
};

export const logoImages = [
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-salesforce-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-pwc-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-siemens-color1.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-kpmg-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-merk-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-sap-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-mitsubishi-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-cisco-color.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-emerson2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-ap2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-best-western2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-financial-times2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-swissre2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-adp2.png',
    'https://storage.googleapis.com/lumen5-site-images/website-assets/logo-cognizant2.png',
];

/* Selectors */
export const getTagIds = () => tags.map(({ id }) => id);
export const getTagNameById = (id: string) => {
    const tag = tags.find(tag => tag.id === id);
    return tag ? tag.displayName : '';
};
export const getDefaultTagId = () => tags[0].id;
export const getVideosByTagId = (tagId: string) => taggedVideos[tagId];
