import 'css/src/components/public-pages/common-sections/VideoPlayer';

import React, { useEffect, useRef, useState } from 'react';

type VideoPlayerProps = {
    videoUrl: string;
    posterUrl?: string;
    autoPlay?: boolean;
    muted?: boolean;
    preload?: string;
    onHoverPlay?: boolean;
    aspectRatio?: string;
    showControls?: boolean;
    loop?: boolean;
    classNames?: string;
    clickHandler?: () => void;
    isShowingPosterOnPause?: boolean;
    isDownloadOptionEnabled?: boolean;
}

const VideoPlayer = React.memo(function Badge({
    posterUrl = ' ',
    videoUrl,
    autoPlay = false,
    muted = false,
    preload = 'metadata',
    onHoverPlay = false,
    aspectRatio = 'w-100',
    showControls = true,
    loop = false,
    classNames = 'm-l5-4 p-l5-4 rounded-video',
    clickHandler = undefined,
    isShowingPosterOnPause = false,
    isDownloadOptionEnabled = true,
}: VideoPlayerProps) {
    const [videoSrc, setVideoSrc] = useState(videoUrl);
    const videoTagRef = useRef<HTMLVideoElement>(null);
    const lastVideoRef = useRef<{ src: string, playbackTime: number }>({ src: '', playbackTime: 0 });
    const videoMoreOptionsControls = isDownloadOptionEnabled ? '' : 'nodownload';

    useEffect(() => {
        const videoInstance = videoTagRef.current;
        if (!videoInstance) {
            return;
        }
        videoInstance.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            videoInstance.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    const handleOnMouseEnter = () => {
        if (onHoverPlay) {
            const promise = videoTagRef.current?.play();
            // prevents "The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22"
            promise?.catch(() => {}); // eslint-disable-line @typescript-eslint/no-empty-function
        }
    };

    const handleOnMouseLeave = () => {
        if (onHoverPlay) {
            videoTagRef.current?.pause();
            if (isShowingPosterOnPause) {
                videoTagRef.current?.load();
            }
        }
    };

    const handleTimeUpdate = () => {
        if (!videoTagRef.current) {
            return;
        }
        if (lastVideoRef.current?.src && lastVideoRef.current.src !== videoTagRef.current?.src) {
            videoTagRef.current.currentTime = lastVideoRef.current.playbackTime;
            const promise = videoTagRef.current?.play();
            // prevents "The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22":
            promise?.catch(() => {}); // eslint-disable-line @typescript-eslint/no-empty-function
            lastVideoRef.current.src = videoTagRef.current?.src;
            lastVideoRef.current.playbackTime = videoTagRef.current.currentTime;
        }
    };

    // Effect to update the video source and save the last playback time before changing the video source.
    useEffect(() => {
        if (videoUrl !== videoSrc) {
            setVideoSrc(videoUrl);
            lastVideoRef.current.src = videoSrc;
            lastVideoRef.current.playbackTime = videoTagRef.current?.currentTime || 0;
        }
    }, [videoUrl, videoSrc]);

    const handleOnClick = (e: React.MouseEvent) => {
        if (clickHandler) {
            e.stopPropagation();
            e.preventDefault();
            clickHandler();
        }
    };


    return (
        <video
            ref={videoTagRef}
            src={videoSrc}
            className={`video-card ${aspectRatio} ${classNames}`}
            loop={loop}
            muted={muted}
            autoPlay={autoPlay}
            preload={preload}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            controls={showControls}
            poster={posterUrl}
            onClick={handleOnClick}
            controlsList={videoMoreOptionsControls}
            disablePictureInPicture
            disableRemotePlayback
        />
    );
});

export default VideoPlayer;
